<script>
import lodash from "lodash";
import AddToCartModal from "@/components/modal/add_to_cart_modal";

export default {
  name: "ProductDetauls",
  setup() {
    return {
      lodash: lodash,
    };
  },
  data() {
    return {
      item: null,
      review: null,
      options: [],
      stock: null,
      stocks: [],
      quantity: 1,

      recentProducts: [],

      loading: false,
      executing: false,
      searching: false,
    };
  },
  methods: {
    vchallengeShopProductDetailGet() {
      this.loading = true;
      this.$store
        .dispatch("vchallengeShopProductDetailGet", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.item = response.data.data;
          this.vchallengeShopProductStockOptionCheckPost();
        });
    },
    vchallengeShopProductStockOptionCheckPost() {
      if (!this.lodash.isNil(this.item)) {
        this.searching = true;
        this.$store
          .dispatch("vchallengeShopProductStockOptionCheckPost", {
            id: this.item._id,
            data: {
              options: this.options,
            },
          })
          .then((response) => {
            this.stocks = response.data.data;
            this.stock = lodash.head(this.stocks);
            this.searching = false;
            this.quantity = 1;
          })
          .catch(() => {
            this.stock = null;
            this.stocks = [];
            this.searching = false;
          });
      }
    },
  },
  mounted() {
    this.vchallengeShopProductDetailGet();
  },
  components: {
    AddToCartModal,
  },
};
</script>

<template>
  <!-- inner-hero-section start -->
  <div class="inner-hero-section">
    <div class="bg-shape">
      <img src="@/assets/images/elements/inner-hero-shape.png" alt="image" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <ul class="page-list">
            <li>
              <router-link :to="{ name: 'home' }" tag="a">
                {{ $t("text.home") }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'products' }" tag="a">
                {{ $t("text.products") }}
              </router-link>
            </li>
            <li class="active" v-if="!lodash.isNil(item)">
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- inner-hero-section end -->

  <template v-if="!lodash.isNil(item) && !lodash.isNil(stock)">
    <section class="pb-120 mt-minus-300">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="contest-cart">
              <div class="contest-cart__left">
                <img
                  :src="getImage(item.media[0].src)"
                  alt="image"
                  class="img-thumbnail img-responsive rounded"
                />
              </div>

              <div class="contest-cart__right">
                <h4 class="subtitle">
                  {{ $t("text.a-more-fun-way-to-shop") }}
                </h4>
                <h3 class="contest-name">
                  {{ item.title }}
                </h3>
                <p>
                  {{ $t("text.product-purchase-description") }}
                </p>
                <div class="contest-num">
                  {{ $t("text.tickets") }}:
                  <span>
                    {{ lodash.floor(item.price.value / 25) }}
                  </span>
                </div>

                <div class="ticket-price">
                  <span class="amount">
                    {{ `$${stock.price.value} ${stock.price.currency}` }}
                  </span>
                  <small>
                    {{ $t("text.product-price") }}
                  </small>
                </div>

                <div
                  class="ticket-wrapper__body pt-0 pl-0 pr-0 mb-30"
                  style="background: transparent"
                >
                  <div
                    class="single-row"
                    style="justify-content: start !important"
                  >
                    <span class="caption mt-2">
                      {{ `${$t("text.quantity")}:` }}
                    </span>

                    <div class="action-btns">
                      <button
                        type="button"
                        class="edit-btn"
                        :disabled="quantity <= 1"
                        @click="quantity -= 1"
                      >
                        <i class="las la-minus"></i>
                      </button>

                      <button
                        style="
                          background: transparent;
                          font-size: 28px;
                          font-weight: 600;
                          margin-left: 0 !important;
                          margin-right: 0 !important;
                        "
                      >
                        {{ quantity }}
                      </button>

                      <button
                        type="button"
                        class="del-btn"
                        :disabled="quantity >= stock.quantity"
                        @click="quantity += 1"
                      >
                        <i class="las la-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mt-sm-0 mt-3">
                  <template v-if="$store.getters.isAuthenticated">
                    <a
                      href="#0"
                      data-toggle="modal"
                      data-target="#add-to-cart-modal"
                      class="cmn-btn style--three"
                    >
                      {{ $t("button.add-to-cart") }}
                    </a>
                  </template>
                  <template v-else>
                    <a
                      href="#0"
                      data-toggle="modal"
                      data-target="#login-modal"
                      class="cmn-btn style--three"
                    >
                      {{ $t("button.sign-in") }}
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-10">
            <div class="contest-description">
              <ul
                class="nav nav-tabs justify-content-center mb-30 pb-4 border-0"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <a
                    class="cmn-btn active"
                    id="description-tab"
                    data-toggle="tab"
                    href="#description"
                    role="tab"
                    aria-controls="description"
                    aria-selected="true"
                    ><span class="mr-3"></span>
                    {{ $t("text.description") }}
                  </a>
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <div class="content-block">
                    <!-- <h3 class="title">
                      {{ $t("text.description") }}
                    </h3> -->
                    <p v-html="toHTML(item.description)"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <AddToCartModal
      :product="item._id"
      :stock="stock._id"
      :quantity="quantity"
    />
  </template>
</template>
