
<script>
export default {
  props: {
    product: {
      type: String,
      required: true,
    },
    stock: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      executing: false,
      addedToCart: false,
    };
  },
  methods: {
    reset() {
      this.executing = false;
      this.addedToCart = false;
    },
    vchallengeShopCartProductItemPost() {
      this.executing = true;
      this.$store
        .dispatch("vchallengeShopCartProductItemPost", {
          data: {
            product: this.product,
            stock: this.stock,
            quantity: this.quantity,
          },
        })
        .then(() => {
          this.$store.dispatch("vchallengeShopCartGet");
          this.executing = false;
          this.addedToCart = true;
        })
        .catch(() => {
          this.executing = false;
          this.addedToCart = false;
        });
    },
  },
};
</script>

<template>
  <div
    class="modal fade"
    id="add-to-cart-modal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="addToCartModal"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-body">
          <div class="account-form-area">
            <button
              type="button"
              class="close-btn"
              data-dismiss="modal"
              aria-label="Close"
              :disabled="executing"
              @click="reset"
            >
              <i class="las la-times"></i>
            </button>
            <h3 class="title">
              {{ $t("text.add-to-cart") }}
            </h3>
            <div class="account-form-wrapper">
              <template v-if="!addedToCart">
                <div class="text-center">
                  <p class="wow">
                    {{
                      $t("text.add-to-cart-description", { value: quantity })
                    }}
                  </p>

                  <button
                    class="cmn-btn mt-4"
                    type="button"
                    @click="vchallengeShopCartProductItemPost"
                  >
                    <span
                      v-if="executing"
                      class="spinner-border spinner-border-sm mb-1 mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>

                    {{ $t("button.accept") }}
                  </button>
                </div>
              </template>
              <template v-else>
                <div class="text-center">
                  <p class="wow">
                    {{ $t("text.added-to-cart-description") }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>